import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuService } from '../../../core/components/menu/services/menu.service';

@Component({
  selector: 'not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit {

  routerLink: string = '';
  constructor(
    public router: Router,
    public menuService: MenuService
  ) { }

  ngOnInit() {}

  public goHome(): void {
    this.router.navigate(['/']);
  }

  public searchForRoute(): void {
    let menuRouterLinkList = this.menuService.getMenuItems()?.map(menuItem => menuItem.routerLink);

    if (this.routerLink) {
      let validRouterLink = menuRouterLinkList?.filter(menuItem => menuItem.includes(this.routerLink))[0];
      if (validRouterLink) {
        this.router.navigateByUrl(validRouterLink);
      }
    }
  }

}
