import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AppConstants {
  public CACHE_TIMEOUT = 30;
  public PAGE_SIZE = 50;

  public MIN_LENGTH_CHAR_SEARCH = 3;

  public EXCEL_FILE_NAME = 'TEMPLATE';

  public RELEASE_CONFIRMATION = 'Are you sure you want to Release to SBT?  This will send positive transactions to SBT.';
  public REJECT_CONFIRMATION = 'Are you sure you want to reject the selected transactions? Rejecting these transactions will result in the boxes being scrapped on the tablet. A box scrapped through the dashboard cannot be restored.';
}
