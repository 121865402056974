<div
  id="app"
  class="h-100 app"
  [ngClass]="settings.theme"
  [dir]="settings.rtl ? 'rtl' : 'ltr'"
>
  <router-outlet></router-outlet>
  <ngx-spinner
    bdColor="rgba(51,51,51,0.7)"
    size="large"
    color="#fff"
    type="ball-clip-rotate"
  ></ngx-spinner>
  <app-msg-banner></app-msg-banner>
</div>
