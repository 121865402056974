<div mat-dialog-title fxLayout="row" fxLayoutAlign="space-between center">
  <h3 class="ml-1 mb-3">{{ data.config.title }}</h3>
  <div class="close-dialog-icon close-dialog-selector mb-0">
    <img
      src="assets/images/banner/red-close-icon.png"
      alt="close"
      (click)="close()"
    />
  </div>
</div>
<mat-dialog-content>
  <div fxLayout="column" fxLayoutGap="10px">
    <form
      class="dark-background-search-form"
      [formGroup]="searchForm"
      fxLayout="row wrap"
      fxLayout.lt-md="column"
      gap="10px"
      fxLayoutAlign="start center"
    >
      <mat-form-field
        fxFlex="25%"
        appearance="outline"
        class="white-mat-form-field-form"
        *ngFor="let column of searchColumns"
      >
        <mat-label>{{ column.showName }}</mat-label>
        <input
          matInput
          [formControlName]="column.fieldName"
          (keyup.enter)="search()"
        />
      </mat-form-field>
      <div fxFlex="25%" class="search-button-wrapper">
        <button
          mat-raised-button
          color="primary"
          type="button"
          (click)="search()"
        >
          Search
        </button>
      </div>
    </form>
    <div class="responsive-table">
      <mat-table [dataSource]="dataSource" class="">
        <ng-container
          *ngFor="let column of data.config.columns"
          matColumnDef="{{ column.fieldName }}"
        >
          <div
            *ngIf="
              column.fieldName === 'selected' &&
              data.config.selectionType != dialogSelectorSelectionType.NONE
            "
          ></div>
          <mat-header-cell *matHeaderCellDef>
            <ng-container
              *ngIf="
                column.fieldName === 'selected' &&
                data.config.selectionType ==
                  dialogSelectorSelectionType.MULTIPLE
              "
            >
              <mat-checkbox
                class="checkbox"
                [aria-label]="'Select/Deselect all'"
                (change)="$event ? masterToggle() : null"
                [checked]="selection.hasValue() && isAllSelected()"
                [indeterminate]="selection.hasValue() && !isAllSelected()"
                color="accent"
              >
                <span class="select-all-text"> (Select all) </span>
              </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="column.fieldName !== 'selected'">
              {{ column.showName }}
            </ng-container>
          </mat-header-cell>
          <mat-cell
            *matCellDef="let element"
            attr.data-label="{{ column.showName }}"
          >
            <ng-container
              *ngIf="
                column.fieldName === 'selected' &&
                data.config.selectionType ==
                  dialogSelectorSelectionType.MULTIPLE
              "
            >
              <mat-checkbox
                class="checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? selection.toggle(element) : null"
                [checked]="selection.isSelected(element)"
                color="accent"
                [aria-label]="'Select/Unselect' + column.fieldName"
              >
              </mat-checkbox>
            </ng-container>
            <ng-container
              *ngIf="
                column.fieldName === 'selected' &&
                data.config.selectionType == dialogSelectorSelectionType.SINGLE
              "
            >
              <mat-radio-button
                class="checkbox"
                (click)="$event.stopPropagation()"
                (change)="$event ? radioButtonSelected(element) : null"
                [checked]="selection.isSelected(element)"
                [aria-label]="'Select/Unselect' + column.fieldName"
              >
              </mat-radio-button>
            </ng-container>
            <ng-container *ngIf="column.fieldName !== 'selected'">
              {{ element[column.fieldName] }}
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
      <mat-paginator [pageSizeOptions]="pageSizeOptions"></mat-paginator>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions fxLayoutAlign="center center">
  <button mat-raised-button color="primary" (click)="selectionComplete()">
    {{ data.config.buttonText }}
  </button>
</mat-dialog-actions>
