import { DialogSelectorColumn } from './dialog-selector-column.model';

export interface DialogSelectorConfig<T> {
  title: string;
  buttonText: string;
  columns: DialogSelectorColumn[];
  allRows: T[];
  selectedRows: T[];
  matchingFieldSelected?: string;
  matchingFieldAll?: string;
  selectionType?: DialogSelectorSelectionType;
}

export enum DialogSelectorSelectionType {
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  NONE = 'none',
}
