import { UserInterfaceMessageService } from './services/user-interface-message.service';
import { Injectable } from '@angular/core';
import { Message } from './models/message.model';
import { BehaviorSubject, Observable, asapScheduler, scheduled, ReplaySubject, EMPTY } from 'rxjs';
import { ConfirmationType } from './enums/confirmation-type.enum';
import { ConfirmationMessage } from './models/confirmation-message.model';
import { skip, take } from 'rxjs/operators';

@Injectable({
	providedIn: 'root',
})
export class MsgBannerService {
	private _messageSubject: BehaviorSubject<Message[]> = new BehaviorSubject<
		Message[]
	>(null);
	private _confirmationMessageSubject: BehaviorSubject<ConfirmationMessage> = new BehaviorSubject<ConfirmationMessage>(null);
	private _confirmationResponseSubject: BehaviorSubject<ConfirmationType> = new BehaviorSubject<ConfirmationType>(null);
	messages$: Observable<Message[]>;
	get confirmationMessage$(): Observable<ConfirmationMessage> {
		return this._confirmationMessageSubject.asObservable();
	}

	constructor(private messageService: UserInterfaceMessageService) {
		this.messages$ = this._messageSubject.asObservable();
	}

	public async addMessageAsync(messageId: number, cssClass: string = '') {
		const result = await this.messageService.getMessageSync(messageId);
		if (result) {
			this.addMessage(result.description, cssClass);
		} else {
			this.handleUnexpectedErr();
		}
	}

	public async addConfirmationMessageAsync(
		messageId: number,
		confirmButtonText: string = 'OK',
		cancelButtonText: string = null,
		msgReplace: any[] = [],
		cssClass: string = '',
	): Promise<Observable<ConfirmationType>> {
		const result = await this.messageService.getMessageSync(messageId);
		if (result) {
			let formattedDescription = this.formatDescription(result, msgReplace);
			
			return this.addConfirmationMessage(
				formattedDescription,
				confirmButtonText,
				cancelButtonText,
				cssClass);
		} else {
			this.handleUnexpectedErr();
			return EMPTY;
		}
	}

	public addMessage(text: string,
		cssClass: string = '') {
		this._messageSubject.next([
			{
				content: text,
				cssClass: cssClass
			} as Message,
		]);
	}

	public addConfirmationMessage(
		text: string,
		confirmButtonText: string = 'OK',
		cancelButtonText: string = null,
		cssClass: string = ''
	): Observable<ConfirmationType> {
		if (text) {
			this._confirmationMessageSubject.next({
				messages: [
					{
						content: text,
					},
				],
				confirmText: confirmButtonText,
				cancelText: cancelButtonText,
				cssClass: cssClass
			} as ConfirmationMessage);
			return this._confirmationResponseSubject.asObservable().pipe(skip(1), take(1));
		}
		return scheduled([ConfirmationType.InvalidRequest], asapScheduler);
	}

	public addMessages(
		texts: string[],
		cssClass: string = ''
	) {
		this._messageSubject.next(
			texts.map(
				(text) =>
				({
					content: text,
					cssClass: cssClass
				} as Message)
			)
		);
	}

	public addConfirmationMessages(
		texts: string[],
		confirmButtonText: string = 'OK',
		cancelButtonText: string = null,
		cssClass: string = ''
	): Observable<ConfirmationType> {
		if (texts?.length > 0) {
			this._confirmationMessageSubject.next({
				messages: texts.map(
					(text) =>
					({
						content: text,
					} as Message)
				),
				confirmText: confirmButtonText,
				cancelText: cancelButtonText,
				cssClass: cssClass
			} as ConfirmationMessage);
			return this._confirmationResponseSubject.asObservable().pipe(skip(1), take(1));
		}
		return scheduled([ConfirmationType.InvalidRequest], asapScheduler);
	}

	public formatDescription(message: any, msgReplace: any[]): string {
		let formattedDescription = message.description;
		if (msgReplace.length > 0) {
			msgReplace.forEach((value, index) => {
				formattedDescription = formattedDescription.replace('#' + index, value);
			});
		}
		return formattedDescription;
	}

	public sendConfirmationResponse(response: ConfirmationType): void {
		this._confirmationResponseSubject.next(response);
	}

	public handleUnexpectedErr(error?: string | any) {
		const unexpectedMsg = 'Internal System Error. Please try again or contact your administrator.';
		const unavailableMsg = 'The application is currently unavailable. Please try again later.';
		if (error) {
			console.log(error);
			this.addMessage(error.status === 503 ? unavailableMsg : unexpectedMsg);
		} else {
			this.addMessage(unexpectedMsg);
		}
	}

	public setSchema(schema: string) {
		this.messageService.setSchema(schema);
	}
}
