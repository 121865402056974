<div class="messagesIcon" mat-dialog-title fxLayoutAlign="end center">
	<img src="assets/images/banner/red-close-icon.png" alt="Close icon" (click)="cancel()">
</div>
<mat-dialog-content class="messageContainer" [ngClass]="data.cssClass">
	<div *ngIf="data.messages.length === 1" class="confirmation-content" fxLayout="row" fxLayoutAlign="center center"
		[innerHTML]="data.messages[0].content" (click)="copyMsg(data.messages[0])">
	</div>
	<ul class="message-items" *ngIf="data.messages.length > 1">
		<li *ngFor="let msg of distinctMsg(data.messages)" class="message-item" (click)="copyMsg(msg)">
			<div class="message-text" [innerHTML]="msg.content"></div>
		</li>
	</ul>
</mat-dialog-content>
<mat-dialog-actions class="msg-banner-actions" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="10px"
	*ngIf="data.confirmText || data.cancelText">
	<button mat-raised-button color="primary" (click)="confirm()" *ngIf="data.confirmText">
		{{ data.confirmText }}
	</button>
	<button mat-stroked-button color="primary" (click)="cancel()" *ngIf="data.cancelText">
		{{ data.cancelText }}
	</button>
</mat-dialog-actions>