import { Injectable, SecurityContext } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { User } from '../models/user.model';
import { DomSanitizer } from '@angular/platform-browser';

const config = require('../../app-config.json');

@Injectable({
  providedIn: 'root',
})
export class GraphService {
  user: User = {
    email: '',
    displayName: '',
    profilePhotoUrl: '',
    groupIDs: [],
    currentPath: '',
    roles: [],
  };

  graphUri = config.resources.graphApi.resourceUri;

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {}

  getGroups() {
    return this.http.get(this.graphUri);
  }

  public loadProfilePhoto() {
    const photo_graph_endpoint =
      'https://graph.microsoft.com/v1.0/me/photo/$value';
    let headers: HttpHeaders = new HttpHeaders();
    headers = headers.append('Content-Type', 'image/jpg');

    this.http
      .get(photo_graph_endpoint, { headers, responseType: 'blob' })
      .subscribe({
        next: (response: Blob) => {
          var urlCreator = window.URL;
          this.user.profilePhotoUrl = this.sanitizer.sanitize(
            SecurityContext.RESOURCE_URL,
            this.sanitizer.bypassSecurityTrustResourceUrl(
              urlCreator.createObjectURL(response)
            )
          );
        },
        error: (this.user.profilePhotoUrl = null),
      });
  }

  getNextPage(nextPage: string) {
    return this.http.get(nextPage);
  }
}
