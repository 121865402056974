import { Component, Inject, NgZone, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Message } from '../models/message.model';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Clipboard } from '@angular/cdk/clipboard';
import { MsgBannerDialogData } from '../models/msg-banner-dialog-data.model';
import { ConfirmationType } from '../enums/confirmation-type.enum';
@Component({
	templateUrl: './msg-banner-dialog.component.html',
	styleUrls: ['./msg-banner-dialog.component.scss'],
})
export class MsgBannerDialogComponent implements OnInit {
	constructor(
		@Inject(MAT_DIALOG_DATA) public data: MsgBannerDialogData,
		private dialogRef: MatDialogRef<MsgBannerDialogComponent>,
		private matSnackBar: MatSnackBar,
		private clipboard: Clipboard,
		private ngZone: NgZone
	) {
		if (data.messages.length === 0) {
			this.dialogRef.close();
		}
	}

	ngOnInit(): void { }

	copyMsg(msg: Message) {
		this.clipboard.copy(msg.content);
		this.matSnackBar.open(
			'Message copied to clipboard!',
			'',
			{
				duration: 1500,
			}
		);
	}

	cancel() {
		this.ngZone.run(() => {
			this.dialogRef.close(ConfirmationType.Cancel);
		})
	}

	confirm() {
		this.ngZone.run(() => {
			this.dialogRef.close(ConfirmationType.Confirm);
		})
	}

	distinctMsg(messages: Message[]): Message[] {
		if (messages) {
			return messages.filter((item, i, arr) => arr.findIndex((t) => t.content === item.content) === i);
		}
	}
}
