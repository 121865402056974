import { Injectable } from '@angular/core';
import { HttpParams, HttpClient } from '@angular/common/http';
import { asapScheduler, forkJoin, Observable, scheduled } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { RestPagedResponse } from '../models/rest-page-response.model';

@Injectable({
  providedIn: 'root',
})
export class SplitRequestService {
  constructor(private http: HttpClient) {}

  private unique<T>(arr: T[], props: string[] = []) {
    return [
      ...new Map(
        Object.keys(arr).map((entry) => [
          props.map((k: any) => entry[k]).join('|'),
          entry,
        ])
      ).values(),
    ] as T[];
  }

  split<T>(
    items: string[],
    queryParamName: string,
    url: string,
    params: HttpParams,
    chunkSize: number,
    responseUniqueBy: string[] = null
  ): Observable<T[]> {
    if (items.length <= chunkSize || chunkSize <= 1) {
      if (items.length > 0) {
        params = params.set(queryParamName, items.join(','));
      }
      return this.http.get(url, { params }) as Observable<T[]>;
    }

    let chunks = items.reduce((all, one, i) => {
      const ch = Math.floor(i / chunkSize);
      all[ch] = [].concat(all[ch] || [], one);
      return all;
    }, []);

    return forkJoin(
      chunks.map((chunk) => {
        let chunkParams = new HttpParams();
        if (chunk?.length > 0) {
          chunkParams = params.set(queryParamName, chunk.join(','));
        }
        return this.http.get<T[]>(url, { params: chunkParams });
      })
    ).pipe(
      switchMap((response: T[][]) => {
        let req = response.reduce((all, curr) => {
          all = all.concat(curr);
          return all;
        }, []);

        if (responseUniqueBy?.length > 0) {
          req = this.unique(req, responseUniqueBy);
        }

        return scheduled([req], asapScheduler);
      })
    );
  }

  splitPaged<T>(
    items: string[],
    queryParamName: string,
    url: string,
    params: HttpParams,
    chunkSize: number,
    responseUniqueBy: string[] = null
  ): Observable<RestPagedResponse<T, any>> {
    if (items.length <= chunkSize || chunkSize <= 1) {
      if (items.length > 0) {
        params = params.set(queryParamName, items.join(','));
      }
      return this.http.get(url, { params }) as Observable<
        RestPagedResponse<T, any>
      >;
    }

    let chunks = items.reduce((all, one, i) => {
      const ch = Math.floor(i / chunkSize);
      all[ch] = [].concat(all[ch] || [], one);
      return all;
    }, []);

    return forkJoin(
      chunks.map((chunk) => {
        let chunkParams = new HttpParams();
        if (chunk?.length > 0) {
          chunkParams = params.set(queryParamName, chunk.join(','));
        }
        return this.http.get<RestPagedResponse<T, any>>(url, {
          params: chunkParams,
        });
      })
    ).pipe(
      switchMap((response: RestPagedResponse<T, any>[]) => {
        let req = response.reduce(
          (all, curr) => {
            all.content = all.content.concat(curr.content);
            all.totalElements = all.totalElements + curr.totalElements;
            return all;
          },
          {
            content: [],
            totalElements: 0,
          } as RestPagedResponse<T, any>
        );

        if (responseUniqueBy?.length > 0) {
          req.content = this.unique(req.content, responseUniqueBy);
          req.totalElements = req.content.length;
        }

        return scheduled([req], asapScheduler);
      })
    );
  }
}
