<div fxLayout="row" fxLayoutAlign="center center" class="mt-15">
  <mat-card appearance="outlined" fxFlex="90" fxFlex.gt-sm="40" class="p-0 mat-elevation-z6 box">
    <div
      fxLayout="column"
      fxLayoutAlign="center center"
      class="bg-primary box-header"
    >
      <mat-icon class="mat-icon-xlg">error</mat-icon>
      <h1 class="error">403</h1>
    </div>
    <mat-card-content
      fxLayout="column"
      fxLayoutAlign="center center"
      fxLayoutGap="20px"
      class="box-content"
    >
      <div
        fxLayout="column"
        fxLayoutAlign="center center"
      >
        <p class="box-text">
          Opps, it seems that you are not authorized to see this page.
        </p>
      </div>
      <div class="box-footer">
        <button
          mat-raised-button
          color="primary"
          class="mat-elevation-z8"
          type="button"
          (click)="goHome()"
        >
          <mat-icon class="m-0">home</mat-icon>
        </button>
      </div>
    </mat-card-content>
  </mat-card>
</div>
