import {
  Component,
  Inject,
  OnInit,
  ViewChild,
  AfterViewInit,
} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogSelectorModalData } from './models/dialog-selector-modal-data.model';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { DialogSelectorColumn } from './models/dialog-selector-column.model';
import { SelectionModel } from '@angular/cdk/collections';
import { environment } from '../../../../environments/environment';
import { MatPaginator } from '@angular/material/paginator';
import { DialogSelectorSelectionType } from './models/dialog-selector-config.model';
import { DataType } from './models/data-type.enum';

@Component({
  selector: 'bwp-dialog-selector',
  templateUrl: './dialog-selector.component.html',
  styleUrls: ['./dialog-selector.component.scss'],
})
export class DialogSelectorComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  dialogSelectorSelectionType = DialogSelectorSelectionType;

  pageSizeOptions = environment.tablePaginationSizes;
  displayedColumns: string[] = [];
  dataSource: MatTableDataSource<any> = new MatTableDataSource<any>();
  columns: DialogSelectorColumn[];
  searchColumns: DialogSelectorColumn[];
  selection = new SelectionModel<any>(true, []);
  isRowSelected: boolean;

  searchForm: UntypedFormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: DialogSelectorModalData<any>,
    public dialogRef: MatDialogRef<DialogSelectorComponent>
  ) {}

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.filterPredicate = this.customFilterPredicate();
  }

  customFilterPredicate(): (data: any, filter: string) => boolean {
    let predicate = (data: any, filter: any) => {
      if (filter) {
        let valid = true;
        this.searchColumns.forEach((searchColumn) => {
          let value = this.searchForm.controls[searchColumn.fieldName].value;
          if (value) {
            valid =
              valid &&
              (data[searchColumn.fieldName] as string)
                .toString()
                ?.toLowerCase()
                .indexOf((value as string).toLowerCase()) >= 0;
          }
        });
        return valid;
      }
      return true;
    };
    return predicate;
  }

  ngOnInit(): void {
    this.columns = this.data.config.columns;
    if (
      this.data.config.selectionType !== this.dialogSelectorSelectionType.NONE
    ) {
      this.columns.push({
        fieldName: 'selected',
        showName: 'Selected',
        searchVisible: false,
        type: DataType.BOOLEAN,
      });
    }

    this.searchColumns = this.columns.filter((column) => column.searchVisible);
    let group = {};
    this.searchColumns.forEach((e) => {
      (group as any)[e.fieldName] = new UntypedFormControl();
    });

    this.searchForm = new UntypedFormGroup(group);

    this.displayedColumns = this.columns.map((column) => column.fieldName);
    this.selection.select(...this.data.config.selectedRows);
    this.dataSource.data = this.data.config.allRows;
  }

  close() {
    this.dialogRef.close(null);
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.data.config.allRows.length;
    return numSelected === numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
    this.isRowSelected = this.selection.hasValue();
  }

  selectionComplete() {
    this.dialogRef.close(this.selection.selected);
  }

  search() {
    this.dataSource.filter = JSON.stringify(this.searchForm.value);
  }

  radioButtonSelected(element: any) {
    this.selection.clear();
    this.selection.select(element);
  }
}
