import {
	Component,
	OnDestroy,
	AfterViewInit,
} from '@angular/core';
import { Message } from './models/message.model';
import { MsgBannerService } from './msg-banner.service';
import { Subscription } from 'rxjs';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MsgBannerDialogComponent } from './msg-banner-dialog/msg-banner-dialog.component';
import { ConfirmationType } from './enums/confirmation-type.enum';
import { BannerQueueType } from './models/banner-queue-type.model';
import { BannerType } from './enums/banner-type.enum';
import { ChangeDetectorRef, NgZone } from '@angular/core';
@Component({
	selector: 'app-msg-banner',
	templateUrl: './msg-banner.component.html',
	styleUrls: ['./msg-banner.component.scss'],
})
export class MsgBannerComponent implements OnDestroy, AfterViewInit {
	isModalOpened = false;
	openedModalType: BannerType;
	shouldReturnConfirmation = false;
	messageList: Message[] = [];
	subscription: Subscription = new Subscription();
	dialogRef: MatDialogRef<MsgBannerDialogComponent>;
	msgBannerQueue: BannerQueueType[] = [];

	constructor(
		private msgBannerService: MsgBannerService,
		private dialog: MatDialog,
		private changeDetectorRef: ChangeDetectorRef,
		private ngZone: NgZone
	) { }

	ngAfterViewInit(): void {
		this.subscription.add(
			this.msgBannerService.messages$
				.subscribe((messages: any) => {
					if (messages?.length > 0) {
						const cssClasses = messages.map((msg: any) => msg.cssClass).join(' ');
						this.openMsgBannerDialog(messages, false, `OK`, null, cssClasses);
					}
				})
		);

		this.subscription.add(
			this.msgBannerService.confirmationMessage$.subscribe(
				(confirmation: any) => {
					if (confirmation) {
						this.openMsgBannerDialog(confirmation.messages, true, confirmation.confirmText, confirmation.cancelText, confirmation.cssClass ?? '');
					}
				}
			)
		);
	}

	openMsgBannerDialog(messages: Message[], shouldSendConfirmation: boolean, confirmText: string, cancelText: string, cssClass: string) {
		this.changeDetectorRef.detach();
		if (messages?.length > 0) {
			if (this.isModalOpened == false) {
				this.isModalOpened = true;

				if (shouldSendConfirmation) {
					this.shouldReturnConfirmation = true;
					this.openedModalType = BannerType.Confirmation;
				} else {
					this.openedModalType = BannerType.Messages;
				}

				this.messageList = messages;

				this.ngZone.run(() => {
					this.dialogRef = this.dialog.open(MsgBannerDialogComponent, {
						panelClass: 'msg-banner-panel',
						backdropClass: 'msg-banner-backdrop',
						data: {
							messages: this.messageList,
							confirmText: confirmText,
							cancelText: cancelText,
							cssClass: cssClass
						},
						autoFocus: false
					});
				});

				this.dialogRef
					.afterClosed()
					.subscribe((response) => {
						this.isModalOpened = false;
						this.changeDetectorRef.reattach();
						if (this.shouldReturnConfirmation) {
							if (response) {
								this.msgBannerService.sendConfirmationResponse(response);
							} else {
								this.msgBannerService.sendConfirmationResponse(ConfirmationType.Cancel);
							}
							this.shouldReturnConfirmation = false;
						}

						while (true) {
							const element = this.msgBannerQueue.shift();
							if (element) {
								if (element.type == BannerType.Messages) {
									this.openMsgBannerDialog(element.messages, false, element.confirmText, element.cancelText, element.cssClass);
								} else {
									this.openMsgBannerDialog(element.messages, true, element.confirmText, element.cancelText, element.cssClass);
									break;
								}
							} else {
								break;
							}
						}

					});
			} else {

				if (this.openedModalType === BannerType.Messages && !shouldSendConfirmation) {
					this.messageList.push(...messages);
					this.dialogRef.componentInstance.data = {
						messages: this.messageList,
						confirmText: confirmText,
						cancelText: cancelText
					};
				} else {
					this.msgBannerQueue.push({
						type: shouldSendConfirmation ? BannerType.Confirmation : BannerType.Messages,
						messages: messages,
						confirmText: confirmText,
						cancelText: cancelText
					} as BannerQueueType);
				}
			}
		}
	}

	ngOnDestroy(): void {
		this.subscription.unsubscribe();
	}
}
