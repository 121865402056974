import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import {
  DialogSelectorConfig,
  DialogSelectorSelectionType,
} from '../models/dialog-selector-config.model';
import { DialogSelectorComponent } from '../dialog-selector.component';
import { Observable, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { DialogSelectorModalData } from '../models/dialog-selector-modal-data.model';

@Injectable({
  providedIn: 'root',
})
export class DialogSelectorService {
  private selection: BehaviorSubject<any> = new BehaviorSubject<any>(null);

  constructor(private dialog: MatDialog) {}

  openDialogSelector<T>(
    config: DialogSelectorConfig<T>,
    dialogConfig: MatDialogConfig = {
      maxWidth: '80%',
    }
  ): Observable<T[]> {
    this.selection.next(null);
    if (config.matchingFieldSelected || config.matchingFieldAll) {
      if (!config.matchingFieldSelected) {
        config.matchingFieldSelected = config.matchingFieldAll;
      }

      if (!config.matchingFieldAll) {
        config.matchingFieldAll = config.matchingFieldSelected;
      }

      let selectedRows: T[] = config.allRows
        .map((row) => {
          let selected =
            config.selectedRows
              .map(
                (selectedRow) =>
                  (selectedRow as any)[config.matchingFieldSelected]
              )
              .indexOf((row as any)[config.matchingFieldAll]) >= 0;
          return selected ? row : null;
        })
        .filter((selectedRow) => selectedRow != null);
      config.selectedRows = selectedRows;
    }

    if (!config.selectionType) {
      config.selectionType = DialogSelectorSelectionType.MULTIPLE;
    }

    let localDialogConfig: MatDialogConfig = {};
    if (dialogConfig) {
      localDialogConfig = dialogConfig;
    }
    localDialogConfig = {
      width: '80%',
      ...localDialogConfig,
      panelClass: (
        'dialog-selector-modal ' + (localDialogConfig.panelClass ?? '')
      ).trim(),
      data: {
        config: config,
      } as DialogSelectorModalData<T>,
    } as MatDialogConfig;

    let dialog = this.dialog.open(DialogSelectorComponent, localDialogConfig);
    dialog.afterClosed().subscribe((response) => {
      this.selection.next(response);
    });
    return this.selection.asObservable().pipe(take(2)) as Observable<T[]>;
  }
}
