import { NgModule } from '@angular/core';
import { SharedModule } from '../shared/shared.module';
import { TopToolbarComponent } from './components/top-toolbar/top-toolbar.component';
import { CommonModule } from '@angular/common';
import { MenuComponent } from './components/menu/menu.component';
import { SelectAutocompleteService } from './services/select-autocomplete.service';
import { ExcelFileService } from './services/excel-file.service';

@NgModule({
  declarations: [TopToolbarComponent, MenuComponent],
  exports: [TopToolbarComponent, MenuComponent],
  imports: [CommonModule, SharedModule],
  providers: [ExcelFileService, SelectAutocompleteService],
})
export class CoreModule {}
