import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'not-authorized',
  templateUrl: './not-authorized.component.html',
  styleUrls: ['./not-authorized.component.scss'],
})
export class NotAuthorizedComponent implements OnInit {
  constructor(public router: Router) {}

  ngOnInit() {}

  public goHome(): void {
    this.router.navigate(['/']);
  }
}
