import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {NotAuthorizedComponent} from './shared/components/not-authorized/not-authorized.component';
import {NotFoundComponent} from './shared/components/not-found/not-found.component';
import {MsalGuard} from '@azure/msal-angular';

const routes: Routes = [
    {
        path: '',
        children: [
            {
                path: '',
                loadChildren: () => import('./modules/dashboard/dashboard.module').then(main => main.DashboardModule),
                canActivate: [MsalGuard]
            }
        ]
    },
    {
        path: 'landing',
        loadChildren: () => import('./modules/landing/landing.module').then(m => m.LandingModule)
    },
    {path: 'not-authorized', component: NotAuthorizedComponent},
    {path: '**', component: NotFoundComponent}
];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
