import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-with-styles';
import { FlatOrderRead } from '../models/read-excel-models/flat-order-read.model';

@Injectable()
export class ExcelFileService {
  maxLengths: Record<string, number> = {};
  fileExtension = '.xlsx';
  fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

  specialActionKey: Record<string, string> = {
    errorMessage: 'errorMessage',
  };

  constructor() {}

  private saveExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], { type: this.fileType });
    // Uncomment the code below for IE or Edge browser, using msSaveorOpenBlob method to download file.

    /*
		if (window.navigator && window.navigator.msSaveOrOpenBlob) {
			window.navigator.msSaveOrOpenBlob(data, fileName + '_' + this.getDateTime() + this.fileExtension);
		} else {
			FileSaver.saveAs(data, fileName + '_' + this.getDateTime() + this.fileExtension);
		}
		*/
    FileSaver.saveAs(
      data,
      fileName + '_' + this.getDateTime() + this.fileExtension
    );
  }

  readExcelFiles(files: any[]): Promise<FlatOrderRead[]>[] {
    const ordersExcelTemplate: FlatOrderRead[] = [];
    const promiseList: Promise<FlatOrderRead[]>[] = [];

    files.forEach((file: Blob) => {
      promiseList.push(
        new Promise((resolve, reject) => {
          let workBook: XLSX.WorkBook = null;
          let jsonData: { [x: string]: any } = null;
          const reader = new FileReader();

          reader.onload = (event) => {
            const data = reader.result;
            workBook = XLSX.read(data, { type: 'binary', cellDates: true });
            jsonData = workBook.SheetNames.reduce(
              (initial: { [x: string]: unknown[] }, name: string | number) => {
                const sheet = workBook.Sheets[name];
                initial[name] = XLSX.utils.sheet_to_json(sheet);
                return initial;
              },
              {}
            );

            const fileOrdersList = Object.keys(jsonData)
              .map((key) => {
                return jsonData[key];
              })
              .reduce((currValue: any[], nextValue: any[]) => {
                if (nextValue.length > 0) {
                  return currValue.push(...nextValue);
                } else {
                  return currValue;
                }
              })
              .map((rawOrder: any[]) => {
                const castOrder: FlatOrderRead = JSON.parse(
                  JSON.stringify(this.jsonKeyRename(rawOrder))
                );
                return this.sanitizeFlatOrder(castOrder);
              });

            ordersExcelTemplate.push(...fileOrdersList);
            resolve(ordersExcelTemplate);
          };

          reader.readAsBinaryString(file);
        })
      );
    });

    return promiseList;
  }

  public exportExcel(
    jsonData: any[],
    fileName: string,
    sheetName: string = 'template',
    useStyle: boolean = true
  ): void {
    const jsons: any[] = [];
    jsonData.forEach((val, index) => (jsons[index] = this.jsonKeyHeader(val)));

    this.calculateLengths(jsons);

    const wscols: XLSX.ColInfo[] | { width: number }[] = [];
    Object.keys(this.maxLengths).forEach((key) => {
      wscols.push({ width: this.maxLengths[key] + 1 });
    });

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(jsons);
    ws['!cols'] = wscols;

    if (useStyle) {
      this.addCustomStyle(ws);
    }
    const wb: XLSX.WorkBook = {
      Sheets: { [sheetName]: ws },
      SheetNames: [sheetName],
    };
    const excelBuffer: any = XLSX.write(wb, {
      bookType: 'xlsx',
      type: 'array',
    });

    this.saveExcelFile(excelBuffer, fileName);
  }

  jsonKeyRename(jsonData: any[]) {
    const output = {};
    for (const key in jsonData) {
      if (typeof jsonData[key].trim === 'function') {
        jsonData[key] = jsonData[key].trim();
      }

      let newKey = key.substring(0, 1).toLocaleLowerCase() + key.substring(1);
      newKey = newKey.replace(/ /g, '');

      if (typeof (output as any)[newKey] === 'number') {
        jsonData[key] = Number(jsonData[key]);
      }

      (output as any)[newKey] = jsonData[key];
    }
    return output;
  }

  private jsonKeyHeader(jsonData: any[]) {
    const output = {};
    for (const key in jsonData) {
      let newKey = key.substring(0, 1).toUpperCase() + key.substring(1);
      newKey = newKey.match(/[A-Z0-9][a-z]*/g).join(' ');
      (output as any)[newKey] = jsonData[key];
    }
    return output;
  }

  private calculateLengths(jsonData: any[]) {
    this.maxLengths = {};

    jsonData.forEach((json) => {
      Object.keys(json).forEach((key) => {
        if (!this.maxLengths[key]) {
          if (json[key]) {
            this.maxLengths[key] = Math.max(
              json[key]
                .toString()
                .split('\n')
                .reduce((a: string | any[], b: string | any[]) =>
                  a.length > b.length ? a : b
                ).length,
              key.length
            );
          } else {
            this.maxLengths[key] = key.length;
          }
        } else if (json[key]) {
          this.maxLengths[key] = Math.max(
            this.maxLengths[key],
            json[key]
              .toString()
              .split('\n')
              .reduce((a: string | any[], b: string | any[]) =>
                a.length > b.length ? a : b
              ).length
          );
        }
      });
    });
  }

  public sanitizeFlatOrder(flatOrder: FlatOrderRead): FlatOrderRead {
    if (flatOrder.shipToCustomer) {
      flatOrder.shipToCustomer = String(flatOrder.shipToCustomer).padStart(
        10,
        '0'
      );
    }
    if (flatOrder.materialNumber) {
      flatOrder.materialNumber = String(flatOrder.materialNumber).padStart(
        18,
        '0'
      );
    }
    return flatOrder;
  }

  private getDateTime(): string {
    const date = new Date();
    const dd = String(date.getDate()).padStart(2, '0');
    const mm = String(date.getMonth() + 1).padStart(2, '0');
    const yyyy = date.getFullYear();

    return mm + dd + yyyy;
  }

  setResultFileName(inputFilename: string) {
    return inputFilename.split('.').slice(0, -1).join('.') + '_result';
  }

  private addCustomStyle(ws: XLSX.WorkSheet) {
    const rowsWithStyle = Object.keys(ws).map((key: string) => {
      if (
        !key.match('!cols') ||
        !key.match('!rows') ||
        !key.match('!rows') ||
        !key.match('!merges') ||
        !key.match('!protect') ||
        !key.match('!protect') ||
        !key.match('!autofilter')
      ) {
        if (ws[key].v && key.startsWith('B') && key !== 'B1') {
          return key.replace(/\D/g, '');
        }
      }
    });
    Object.keys(ws).forEach((key: string) => {
      if (
        !key.match('!cols') ||
        !key.match('!rows') ||
        !key.match('!rows') ||
        !key.match('!merges') ||
        !key.match('!protect') ||
        !key.match('!protect') ||
        !key.match('!autofilter')
      ) {
        // Header
        if (ws[key].v && key.replace(/\D/g, '') === '1') {
          ws[key].s = {
            font: {
              sz: 14,
              color: { rgb: '000' },
              bold: true,
            },
            border: {
              top: { style: 'thin', color: { auto: 1 } },
              right: { style: 'thin', color: { auto: 1 } },
              bottom: { style: 'thin', color: { auto: 1 } },
              left: { style: 'thin', color: { auto: 1 } },
            },
            fill: {
              patternType: 'solid', // none / solid
              fgColor: { rgb: 'DDDDDD' },
              bgColor: { rgb: 'DDDDDD' },
            },
          };
        }
        // Error Cells
        if (ws[key].v && key.startsWith('A') && key !== 'A1') {
          ws[key].s = {
            font: {
              sz: 12,
              color: { rgb: 'FF0000' },
              bold: false,
            },
          };
        }
        // Ship-To Rows
        if (
          rowsWithStyle.includes(key.replace(/\D/g, '')) &&
          !key.startsWith('A')
        ) {
          ws[key].s = {
            fill: {
              patternType: 'solid', // none / solid
              fgColor: { rgb: 'FFFF00' },
              bgColor: { rgb: 'FFFF00' },
            },
          };
        }
        if (ws[key].v) {
          ws[key].s
            ? (ws[key].s['alignment'] = {
                wrapText: key.replace(/\D/g, '') !== '1' ? true : false,
                vertical: 'center',
                horizontal: key.startsWith('A') ? 'left' : 'center',
              })
            : (ws[key].s = {
                alignment: {
                  wrapText: key.replace(/\D/g, '') !== '1' ? true : false,
                  vertical: 'center',
                  horizontal: key.startsWith('A') ? 'left' : 'center',
                },
              });
        }
      }
    });
  }
}
