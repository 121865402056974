import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AppInterceptor implements HttpInterceptor {
  requestsInProcess = 0;
  constructor(private spinner: NgxSpinnerService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let noSpinnerFlag = false;
    if (req.headers.has(environment.noLoadingSpinnerHeader)) {
      noSpinnerFlag = true;
      req.headers.delete(environment.noLoadingSpinnerHeader);
    } else {
      this.requestsInProcess++;
      setTimeout(() => {
        this.spinner.show();
      }, 0);
    }
    // This is used for internationalization. For any request is setting the language in Accept-Language request header
    // This will be read on the Backend side to set the Backend locale language
    req = req.clone({
      headers: req.headers.set('Accept-Language', 'en'),
    });
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        const started = Date.now();
        const elapsed = Date.now() - started;
        console.log(
          `Request for ${req.urlWithParams} failed after ${elapsed} ms.`
        );
        return throwError(error);
      }),
      finalize(() => {
        if (!noSpinnerFlag) {
          this.requestsInProcess--;
          if (this.requestsInProcess === 0) {
            this.spinner.hide();
          }
        }
      })
    );
  }
}
